:root {
  --primary: #093a39;
  --primaryRgb: 9, 58, 57;

  --primaryLight: #0b4544;
  --primaryLightRgb: 11, 69, 68;

  --secondary: #ff7300;
  --secondaryRgb: 255, 115, 0;

  --textcolor: #4e4e4e;
  --textcolorRgb: 78, 78, 78;

  --gray: gray;
  --lightgray: #e1e1e1;
  --black: #000;
  --blackRgb: 0, 0, 0;

  --white: #fff;
  --delete: #e74c3c;
  --success: #2bd628;
}

* {
  font-family: 'Poppins', sans-serif;
  outline-color: var(--secondary);
}

input {
  margin: 0;
  padding: 0;
}

/* scrolls  */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  padding: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightgray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(var(--blackRgb), 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--blackRgb), 0.3);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.react-modal-overlay {
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content:center;
}

.react-modal-content {
  width: 100%;
  display: block;
  max-width: 576px;
  background-color: var(--white);
  padding: 3rem;
  position: relative;
  border-radius: 0.25rem;
}

@media screen and (max-width: 675px) {
  .react-modal-content {
    max-width: 376px;
  }
}


